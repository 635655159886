<template>
  <section
    class="w-full bg-clr-black py-28 flex justify-center items-center overflow-hidden">
    <div
      class="px-4 max-w-screen-xl w-full flex flex-col items-center text-clr-white">
      <div class="w-full flex flex-col lg:flex-row gap-8">
        <div class="flex-1">
          <RouterLink
            to="/"
            class="customAnimate w-fit flex items-center justify-center">
            <img
              src="../assets/images/ninjaLogo.png"
              alt=""
              class="h-12 block" />
          </RouterLink>
          <MainBtn
            :to="'/contact'"
            :text="'CONTACT US'" class="mt-8"/>
        </div>
        <div class="flex-1 flex flex-col md:flex-row gap-8">
          <nav class="customAnimate flex-1">
            <ul class="flex flex-col gap-4">
              <li
                class="text-clr-white font-medium text-fs-medium hover:text-clr-accent hover:translate-x-2 duration-300">
                <RouterLink
                  to="/"
                  class="p-1">
                  Home
                </RouterLink>
              </li>
              <li
                class="text-clr-white font-medium text-fs-medium hover:text-clr-accent hover:translate-x-2 duration-300">
                <RouterLink
                  to="/portfolio"
                  class="p-1">
                  Portfolio
                </RouterLink>
              </li>
              <li
                class="text-clr-white font-medium text-fs-medium hover:text-clr-accent hover:translate-x-2 duration-300">
                <RouterLink
                  to="/services"
                  class="p-1">
                  Services
                </RouterLink>
              </li>
              <li
                class="text-clr-white font-medium text-fs-medium hover:text-clr-accent hover:translate-x-2 duration-300">
                <RouterLink
                  to="/contact"
                  class="p-1">
                  Contact
                </RouterLink>
              </li>
            </ul>
          </nav>
          <div class="flex-1">
            <ul class="customAnimate flex flex-col gap-4">
              <li
                class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                <a
                  href="tel:%20+61437779671"
                  target="blank"
                  class="p-1">
                  +61437779671
                </a>
              </li>
              <li
                class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                <a
                  href="https://www.facebook.com/siteninjas/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
                  target="blank"
                  class="p-1">
                  Facebook
                </a>
              </li>
              <li
                class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                <a
                  href="https://www.instagram.com/invites/contact/?i=xxna9zb89kco&utm_content=pevnk3j"
                  target="blank"
                  class="p-1">
                  Instagram
                </a>
              </li>
              <li
                class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                <a
                  href="https://www.quora.com/profile/Site-Ninjas-Digital-Design-and-Marketing"
                  target="blank"
                  class="p-1">
                  Quora
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainBtn from "@/components/MainBtn.vue";
//mixin
import FadeInMixin from "@/mixins/fadeInMixin.js";
export default {
  name: "MainFooter",
  mixins: [FadeInMixin],
  components: {
    MainBtn,
  },
};
</script>
<style>
/* "customAnimate " => use this class to animate element */
.customFadeIn {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.customFadeOut {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}
</style>
