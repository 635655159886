<template>
  <section class="w-full bg-clr-white py-28 flex justify-center items-center">
    <div
      class=" w-full flex lg:items-center flex-col lg:flex-row">
      <div class="w-full">
        <!-- section headers -->
        <div class="w-full text-center">
          <h2 class="customAnimate mb-16">
            <span
              class="text-fs-36 md:text-[42px] lg:text-fs-large font-medium lg:leading-[5rem] text-clr-black">
              <span class="font-thin">Our</span>
              Clients
            </span>
          </h2>
        </div>
        <!-- === slider === -->
        <div class=" flex gap-14 overflow-hidden">
          <div class="flex gap-14 animate-loop-scroll">
            <img
              v-for="(client, index) in clients"
              :key="index"
              class="block h-20 lg:h-28 max-w-none"
              :src="require('@/assets/images/' + client)"
              alt="client" />
          </div>
          <div class="flex gap-14 animate-loop-scroll">
            <img
              v-for="(client, index) in clients"
              :key="index"
              class="block h-20 lg:h-28 max-w-none"
              :src="require('@/assets/images/' + client)"
              alt="client" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//mixin
import FadeInMixin from "@/mixins/fadeInMixin.js";
export default {
  name: "ClientsSlider",
  mixins: [FadeInMixin],
  data() {
    return {
      clients: [
        "client-1.png",
        "client-2.png",
        "client-3.png",
        "client-4.png",
        "client-6.png",
        "client-7.png",
        "client-8.png",
        "client-9.png",
        "client-10.png",
      ],
    };
  },
};
</script>

<style scoped></style>
