<template>
  <router-link
    :to="to"
    class="w-[230px] h-[70px] px-4 bg-clr-accent hover:bg-clr-accent-light hover:scale-[1.010] rounded-full flex items-center justify-end gap-4 text-black text-fs-normal font-medium group transition-all ease-in-out duration-500">
    <span
      class="tracking-widest group-hover:scale-[1.03] transition-all ease-in-out duration-500"
      >{{ text }}</span
    >
    <span
      class="w-10 h-10 bg-clr-black rounded-full text-transparent flex items-center justify-center group-hover:scale-[1.15] transition-all ease-in-out duration-500">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#FF9800"
        class="w-5 h-5">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
      </svg>
    </span>
  </router-link>
</template>

<script>
export default {
  name: "MainBtn",
  props: {
    to: String,
    text: String,
  },
};
</script>
