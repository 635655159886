<template>
  <!-- hero section -->
  <section class="w-full bg-clr-black h-screen flex justify-center items-end">
    <div
      class="px-4 sm:px-8 lg:px-12 xl:px-2 max-w-screen-xl w-full text-clr-off-white mb-32">
      <h1 class="mb-14 w-3/4">
        <span
          class="text-fs-36 md:text-[58px] lg:text-fs-larger font-medium lg:leading-[6rem]">
          Site Ninjas <span class="font-thin">Digital</span> <br />
          Design <span class="font-thin">and</span>
          marketing
        </span>
      </h1>
      <p class="max-w-2xl text-fs-normal text-clr-gray mb-14">
        <span class="leading-3">
          Based in Sydney's Northern Beaches, Site Ninjas offers top-tier
          Website Design, Development, and Digital Marketing services. With over
          25 years of combined experience, we're here to elevate your online
          presence. Contact us now to get started!
        </span>
      </p>
      <div class="flex items-center gap-8">
        <MainBtn
          :to="'/contact'"
          :text="'CONTACT US'" />
        <RouterLink
          to="/portfolio"
          class="text-fs-small flex items-center gap-4 group">
          <span class="tracking-widest font-medium">VIEW WORKS</span>
          <span
            class="w-10 h-10 bg-clr-gray-dark rounded-full text-transparent flex items-center justify-center group-hover:scale-[1.15] transition-all ease-in-out duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#E6E6E6"
              class="w-5 h-5">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </span>
        </RouterLink>
      </div>
    </div>
  </section>
  <!-- first section -->
  <section class="w-full bg-clr-white py-28 flex justify-center items-center">
    <div
      class="px-4 sm:px-8 lg:px-12 xl:px-2 max-w-screen-xl w-full flex lg:items-center flex-col lg:flex-row">
      <!-- text side -->
      <div class="w-full lg:w-3/5 mb-8 lg:mb-0">
        <h2 class="customAnimate mb-8">
          <span
            class="text-fs-36 md:text-[42px] lg:text-fs-large font-medium lg:leading-[5rem] text-clr-black">
            Discover <br />
            <span class="font-thin">Site Ninjas</span>
          </span>
        </h2>
        <p
          class="customAnimate w-full lg:max-w-md text-fs-normal font-extralight text-clr-gray-light mb-8">
          <span class="leading-3">
            Based on the Northern Beaches in Sydney, Site Ninjas are no1 in
            providing our clients with high quality and professional Website
            Design and Web Development services.
          </span>
        </p>
        <p
          class="customAnimate lg:max-w-md text-fs-normal font-extralight text-clr-gray-light mb-8">
          <span class="leading-3">
            We can help to either guide you through your first business venture
            by reducing the stress of making a website or giving assistance with
            an existing website. With over 25 years of combined experience
            between our team of nerdy Ninjas you can be sure you are in safe
            hands.
          </span>
        </p>
        <p
          class="customAnimate lg:max-w-md text-fs-normal font-extralight text-clr-gray-light mb-8">
          <span class="leading-3">
            Whether it be Website Design, Web Development, Digital Marketing,
            Logo and Graphic Design or Ecommerce. Get in contact to make a start
            in that business you’ve been putting off!
          </span>
        </p>
        <!-- founder -->
        <div class="customAnimate flex items-center">
          <!-- img -->
          <div class="relative w-fit">
            <img
              class="block h-[90px] w-[90px] object-cover object-top rounded-full"
              src="../assets/images/founder.jpg"
              alt="founder of website" />
            <div
              class="absolute bottom-0 right-0 bg-clr-accent size-[30px] rounded-full flex items-center justify-center">
              <span class="leading-relaxed text-3xl mt-3">"</span>
            </div>
          </div>
          <!-- role -->
          <div class="pl-8">
            <h6 class="text-fs-18 font-medium">
              Passionatily Creating
              <span class="text-clr-gray-light font-extralight"
                >Design Wonders:</span
              >
              <br />
              Unleashing
              <span class="text-clr-gray-light font-extralight"
                >Boundless Creativity</span
              >
            </h6>
          </div>
        </div>
      </div>
      <!-- image side -->
      <div class="customAnimate w-full lg:w-2/5">
        <img
          class="block p-2"
          src="../assets/images/photo-1.jpg"
          alt="first section image" />
      </div>
    </div>
  </section>
  <!-- second section -->
  <section
    class="w-full bg-clr-black pt-28 flex justify-center items-center overflow-hidden">
    <div class="px-4 max-w-screen-xl w-full flex flex-col items-center">
      <!-- headings -->
      <div class="w-full mb-28">
        <!-- upper text -->
        <div
          class="customAnimate text-clr-gray w-4/5 mb-12 text-right relative ml-auto lg:ml-0">
          <span>
            Professionals focused on helping your brand<br />
            grow and move forward.
          </span>
          <div
            class="absolute ml-6 top-3 left-full h-[1px] w-[600px] bg-clr-gray"></div>
        </div>
        <!-- main heading of section -->
        <div class="customAnimate flex items-center justify-center gap-8">
          <!-- image capsule -->
          <div
            class="h-20 w-[250px] rounded-full overflow-hidden hidden md:block">
            <img
              class="block w-full hover:scale-105 duration-500"
              src="../assets/images/photo-2.jpg"
              alt="" />
          </div>
          <!-- heading -->
          <div>
            <h2
              class="text-fs-36 md:text-fs-58 lg:text-fs-larger font-medium text-clr-white">
              Unique <span class="font-thin">Ideas</span>
            </h2>
          </div>
        </div>
        <!-- main heading of section 2 -->
        <div
          class="customAnimate flex flex-col md:flex-row items-center justify-center gap-8">
          <!-- heading -->
          <div>
            <h2
              class="text-fs-36 md:text-fs-58 lg:text-fs-larger font-medium text-clr-white">
              For Your <span class="font-thin">Business.</span>
            </h2>
          </div>
          <!-- main btn capsule -->
          <MainBtn
            :to="'/services'"
            :text="'OUR SERVICES'" />
        </div>
        <!-- headings end  -->
      </div>
      <!-- ==== boxes // -->
      <div
        class="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 text-clr-white border-t-[1px] border-clr-gray-dark">
        <div class="lg:border-l-[1px] md:border-r-[1px] border-clr-gray-dark">
          <RouterLink to="/services/web-design">
            <div class="block py-[60px] px-[30px]">
              <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
                <span
                  >Website <br />
                  Design</span
                >
              </h5>
              <p
                class="text-fs-normal font-extralight text-clr-gray-light mb-[30px]">
                <span>
                  Elevate your online presence with visually stunning websites
                  tailored to your brand.
                </span>
              </p>
              <div>
                <span
                  class="w-10 h-10 bg-clr-accent rounded-full text-transparent flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    class="w-5 h-5">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </span>
              </div>
            </div>
          </RouterLink>
        </div>
        <div
          class="border-t-[1px] lg:border-t-0 lg:border-r-[1px] border-clr-gray-dark">
          <RouterLink to="/services/web-development">
            <div class="block py-[60px] px-[30px]">
              <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
                <span
                  >Web <br />
                  Development</span
                >
              </h5>
              <p
                class="text-fs-normal font-extralight text-clr-gray-light mb-[30px]">
                <span>
                  Build responsive websites aligned with your business
                  objectives for an exceptional user experience.
                </span>
              </p>
              <div>
                <span
                  class="w-10 h-10 bg-clr-accent rounded-full text-transparent flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    class="w-5 h-5">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </span>
              </div>
            </div>
          </RouterLink>
        </div>
        <div
          class="border-t-[1px] lg:border-t-0 md:border-r-[1px] border-clr-gray-dark">
          <RouterLink to="/services/wordpress">
            <div class="block py-[60px] px-[30px]">
              <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
                <span
                  >WordPress, WooCommerce <br />
                  and Online Shops
                </span>
              </h5>
              <p
                class="text-fs-normal font-extralight text-clr-gray-light mb-[30px]">
                <span>
                  Comprehensive solutions for seamless online shopping
                  experiences.
                </span>
              </p>
              <div>
                <span
                  class="w-10 h-10 bg-clr-accent rounded-full text-transparent flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    class="w-5 h-5">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </span>
              </div>
            </div>
          </RouterLink>
        </div>
        <div
          class="border-t-[1px] lg:border-t-0 lg:border-r-[1px] border-clr-gray-dark">
          <RouterLink to="/services/brand-identity">
            <div class="block py-[60px] px-[30px]">
              <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
                <span>
                  Brand <br />
                  Identity</span
                >
              </h5>
              <p
                class="text-fs-normal font-extralight text-clr-gray-light mb-[30px]">
                <span>
                  Establish a strong and memorable brand identity with custom
                  design services.
                </span>
              </p>
              <div>
                <span
                  class="w-10 h-10 bg-clr-accent rounded-full text-transparent flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#000000"
                    class="w-5 h-5">
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
                  </svg>
                </span>
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
    </div>
  </section>

  <!-- third section get a website -->
  <section class="w-full bg-clr-white py-28 flex justify-center items-center">
    <div
      class="px-4 sm:px-8 lg:px-12 xl:px-2 max-w-screen-xl w-full flex flex-col lg:flex-row items-center gap-10">
      <div class="w-full">
        <!-- section headers -->
        <div class="w-full text-center">
          <h2 class="customAnimate mb-16">
            <span
              class="text-fs-36 md:text-[42px] lg:text-fs-large font-medium lg:leading-[5rem] text-clr-black">
              Get a Website <br />
              <span class="font-thin">That</span>
              Stands Out
              <span class="font-thin">and Get Ranked</span>
            </span>
          </h2>
        </div>
        <!-- section cards ===  -->
        <div
          class="flex flex-col lg:flex-row text-center gap-4 mb-16 pb-16 border-b border-clr-gray-light">
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate mb-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-14 h-14">
                <path
                  fill-rule="evenodd"
                  d="M2.25 5.25a3 3 0 0 1 3-3h13.5a3 3 0 0 1 3 3V15a3 3 0 0 1-3 3h-3v.257c0 .597.237 1.17.659 1.591l.621.622a.75.75 0 0 1-.53 1.28h-9a.75.75 0 0 1-.53-1.28l.621-.622a2.25 2.25 0 0 0 .659-1.59V18h-3a3 3 0 0 1-3-3V5.25Zm1.5 0v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5Z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Responsive Clean Design
            </h5>
            <p
              class="customAnimate text-fs-normal font-extralight text-clr-gray-light mb-8 max-w-md lg:max-w-max">
              <span>
                Our unique approach to website design ensures impact across all
                devices, with a single, tailored website seamlessly catering to
                all screen resolutions.
              </span>
            </p>
          </div>
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate mb-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-14 h-14">
                <path
                  fill-rule="evenodd"
                  d="M1.5 5.625c0-1.036.84-1.875 1.875-1.875h17.25c1.035 0 1.875.84 1.875 1.875v12.75c0 1.035-.84 1.875-1.875 1.875H3.375A1.875 1.875 0 0 1 1.5 18.375V5.625ZM21 9.375A.375.375 0 0 0 20.625 9h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5Zm0 3.75a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5a.375.375 0 0 0 .375-.375v-1.5ZM10.875 18.75a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h7.5ZM3.375 15h7.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375h-7.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375Zm0-3.75h7.5a.375.375 0 0 0 .375-.375v-1.5A.375.375 0 0 0 10.875 9h-7.5A.375.375 0 0 0 3 9.375v1.5c0 .207.168.375.375.375Z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Logo, Graphic and Animation Design
            </h5>
            <p
              class="customAnimate text-fs-normal font-extralight text-clr-gray-light mb-8 max-w-md lg:max-w-max">
              <span>
                Whether startup or established, we craft visually engaging
                branding concepts, from logos to explainer videos, meeting all
                needs for new ventures or redesigns.
              </span>
            </p>
          </div>
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate mb-8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="w-14 h-14">
                <path
                  fill-rule="evenodd"
                  d="M2.25 2.25a.75.75 0 0 0 0 1.5H3v10.5a3 3 0 0 0 3 3h1.21l-1.172 3.513a.75.75 0 0 0 1.424.474l.329-.987h8.418l.33.987a.75.75 0 0 0 1.422-.474l-1.17-3.513H18a3 3 0 0 0 3-3V3.75h.75a.75.75 0 0 0 0-1.5H2.25Zm6.54 15h6.42l.5 1.5H8.29l.5-1.5Zm8.085-8.995a.75.75 0 1 0-.75-1.299 12.81 12.81 0 0 0-3.558 3.05L11.03 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l2.47-2.47 1.617 1.618a.75.75 0 0 0 1.146-.102 11.312 11.312 0 0 1 3.612-3.321Z"
                  clip-rule="evenodd" />
              </svg>
            </div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Call to Action and Targeted Content
            </h5>
            <p
              class="customAnimate text-fs-normal font-extralight text-clr-gray-light mb-8 max-w-md lg:max-w-max">
              <span>
                Captivating visuals are pivotal for digital marketing success,
                complemented by our SEO expertise, ensuring effective user
                engagement strategies.
              </span>
            </p>
          </div>
        </div>

        <!-- section number cards  ===  -->
        <div class="flex flex-col lg:flex-row text-center gap-4 mt-16 pt-16">
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate text-5xl mb-4">100+</div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Projects Assassinated
            </h5>
          </div>
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate text-5xl mb-4">75+</div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Impressed Clients
            </h5>
          </div>
          <div class="flex-1 flex items-center flex-col justify-center">
            <div class="customAnimate text-5xl mb-4">6+</div>
            <h5 class="customAnimate text-fs-20 font-medium mb-[30px]">
              Expert Site Ninjas
            </h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ClientsSlider />
  <!-- phases slider -->
  <section
    class="w-full bg-clr-black pt-28 flex justify-center items-center overflow-hidden">
    <div class="px-4 max-w-screen-xl w-full flex flex-col items-center">
      <div class="w-full mb-16">
        <!-- upper text -->
        <div
          class="customAnimate text-clr-gray-light w-full lg:w-4/5 mb-12 text-center lg:text-right relative">
          <span>
            Leave the website to us.<br />
            You focus on your business.
          </span>
          <div
            class="absolute ml-6 top-3 left-full h-[1px] w-[600px] bg-clr-gray-light"></div>
        </div>
        <!-- section headers -->
        <div class="text-center">
          <h2
            class="customAnimate text-fs-36 md:text-fs-44 lg:text-fs-58 xl:text-fs-large text-clr-white font-medium">
            <span class="font-thin">Our</span>
            Stress Free <span class="font-thin"><br />Process</span>
          </h2>
        </div>
      </div>

      <PhaseSlider />
    </div>
  </section>

  <!-- 4th / testimonial section -->
  <section
    class="w-full bg-clr-gray-lighter pt-28 flex justify-center items-center overflow-hidden">
    <div class="px-4 max-w-screen-xl w-full flex flex-col items-center">
      <div class="w-full mb-16">
        <!-- upper text -->
        <div
          class="customAnimate text-clr-gray-light w-full lg:w-4/5 mb-12 text-center lg:text-right relative">
          <span>
            Customer reviews are a valuable source <br />
            of information for both businesses and consumers.
          </span>
          <div
            class="absolute ml-6 top-3 left-full h-[1px] w-[600px] bg-clr-gray-light"></div>
        </div>
        <!-- section headers -->
        <div class="text-center">
          <h2
            class="customAnimate text-fs-36 md:text-fs-44 lg:text-fs-58 xl:text-fs-large text-clr-black font-medium">
            Customer <span class="font-thin">Voices:</span><br />
            Hear What <span class="font-thin">They Say!</span>
          </h2>
        </div>
      </div>

      <TestimonialSlider class="w-full" />
    </div>
  </section>

  <!-- footer  -->
  <MainFooter />
</template>

<script>
// @ is an alias to /src
import MainBtn from "@/components/MainBtn.vue";
import TestimonialSlider from "@/components/TestimonialSlider.vue";
import PhaseSlider from "@/components/PhaseSlider.vue";
import ClientsSlider from "@/components/ClientsSlider.vue";
import MainFooter from "@/components/MainFooter.vue";
// import LogoSvg from "@/components/LogoSvg.vue";
//mixin
import FadeInMixin from "@/mixins/fadeInMixin.js";

export default {
  name: "HomeView",
  mixins: [FadeInMixin],
  components: {
    MainBtn,
    TestimonialSlider,
    PhaseSlider,
    ClientsSlider,
    MainFooter,
    // LogoSvg,
  },
};
</script>
