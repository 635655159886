<template>
  <div class="w-full py-4 px-8 bg-clr-black transition-all ease-in-out">
    <nav class="flex items-center justify-between">
      <div class="text-[42px] z-[99999]">
        <RouterLink
          to="/"
          class="flex items-center justify-center">
          <img
            src="../assets/images/ninjaLogo.png"
            alt=""
            class="h-12 block" />
          >
        </RouterLink>
      </div>

      <button
        @click="toggleMenu"
        class="relative w-8 h-8 z-[9999] bg-black">
        <div
          class="h-[3px] w-8 bg-clr-white rounded-full absolute left-0 transform transition-transform duration-500"
          :class="{
            'h-[3px] top-4 -rotate-45': isOpen,
            'top-2': !isOpen,
          }"></div>
        <div
          class="h-[2px] w-8 bg-clr-white rounded-full absolute left-0 top-4 transform transition-transform duration-500"
          :class="{ 'h-[3px] rotate-45': isOpen, '': !isOpen }"></div>
        <div
          class="h-[2px] w-5 bg-clr-white rounded-full absolute left-0 top-6 transform transition-transform duration-500"
          :class="{
            'h-[3px]  -rotate-45 hidden': isOpen,
            ' block': !isOpen,
          }"></div>
      </button>
    </nav>
    <!-- full screen menu  -->
    <!-- Use transition component for smooth animation -->
    <transition name="fade">
      <div
        v-show="isOpen"
        class="absolute w-full z-[999] top-0 left-0 right-0 bottom-0 h-screen bg-clr-black flex items-center justify-center">
        <div
          class="px-8 py-2 max-w-screen-xl w-full h-full flex items-center justify-center">
          <!-- left side -->
          <div
            class="lg:w-[45%] h-full flex items-center py-4 px-8 lg:border-r-[1px] border-clr-gray-dark text-center lg:text-left">
            <transition
              name="slide"
              appear>
              <nav
                v-show="isOpen"
                class="">
                <ul class="flex flex-col gap-4">
                  <li
                    class="text-clr-white font-medium text-[34px] hover:text-clr-accent hover:translate-x-2 duration-300">
                    <RouterLink
                      to="/"
                      class="p-1"
                      @click="closeMenu">
                      Home
                    </RouterLink>
                  </li>
                  <li
                    class="text-clr-white font-medium text-[34px] hover:text-clr-accent hover:translate-x-2 duration-300">
                    <RouterLink
                      to="/portfolio"
                      class="p-1"
                      @click="closeMenu">
                      Portfolio
                    </RouterLink>
                  </li>
                  <li
                    class="text-clr-white font-medium text-[34px] hover:text-clr-accent hover:translate-x-2 duration-300">
                    <RouterLink
                      to="/services"
                      class="p-1"
                      @click="closeMenu">
                      Services
                    </RouterLink>
                  </li>
                  <li
                    class="text-clr-white font-medium text-[34px] hover:text-clr-accent hover:translate-x-2 duration-300">
                    <RouterLink
                      to="/contact"
                      class="p-1"
                      @click="closeMenu">
                      Contact
                    </RouterLink>
                  </li>
                </ul>
              </nav>
            </transition>
          </div>
          <!-- right side -->
          <div
            class="hidden w-[55%] h-full lg:flex flex-col items-center justify-center gap-20 py-8 pl-16">
            <!-- upper div -->
            <div
              class="w-full flex justify-between border-b-[1px] border-clr-gray-dark">
              <Transition
                name="fadeup"
                appear>
                <div
                  v-show="isOpen"
                  class="customAnimate mb-16">
                  <h6 class="mb-8 text-fs-18 font-medium text-clr-white">
                    Services
                  </h6>
                  <ul class="flex flex-col gap-4">
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <RouterLink
                        to="/services/web-design"
                        @click="closeMenu"
                        class="p-1">
                        Web Design
                      </RouterLink>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <RouterLink
                        to="/services/web-development"
                        @click="closeMenu"
                        class="p-1">
                        Web Development
                      </RouterLink>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <RouterLink
                        to="/services/wordpress"
                        @click="closeMenu"
                        class="p-1">
                        wordpress / wooCommerce
                      </RouterLink>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <RouterLink
                        to="/services/brand-identity"
                        @click="closeMenu"
                        class="p-1">
                        Brand Identity
                      </RouterLink>
                    </li>
                  </ul>
                </div>
              </Transition>
              <Transition
                name="fadeup"
                appear>
                <div
                  v-show="isOpen"
                  class="customAnimate mb-16">
                  <h6 class="mb-8 text-fs-18 font-medium text-clr-white">
                    Contact
                  </h6>
                  <ul class="flex flex-col gap-4">
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <a
                        href="tel:%20+61437779671"
                        target="blank"
                        class="p-1">
                        +61437779671
                      </a>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <a
                        href="https://www.facebook.com/siteninjas/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
                        target="blank"
                        class="p-1">
                        Facebook
                      </a>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <a
                        href="https://www.instagram.com/invites/contact/?i=xxna9zb89kco&utm_content=pevnk3j"
                        target="blank"
                        class="p-1">
                        Instagram
                      </a>
                    </li>
                    <li
                      class="text-fs-normal text-clr-gray hover:text-clr-white duration-300 hover:translate-x-2">
                      <a
                        href="https://www.quora.com/profile/Site-Ninjas-Digital-Design-and-Marketing"
                        target="blank"
                        class="p-1">
                        Quora
                      </a>
                    </li>
                  </ul>
                </div>
              </Transition>
            </div>
            <!-- lower div -->
            <div class="w-full flex justify-between">
              <Transition
                name="fadeup"
                appear>
                <div
                  v-show="isOpen"
                  class="customAnimate mb-16">
                  <h6 class="mb-4 text-fs-18 font-medium text-clr-white">
                    Location
                  </h6>
                  <p class="w-44 text-clr-gray">
                    <span> 10 Whittle Avenue, Balglowlah, NSW </span>
                  </p>
                </div>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "MainNav",
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    closeMenu() {
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fadeup-enter-from,
.fadeup-leave-to {
  transform: translateY(50%);
  opacity: 0;
}
.fadeup-enter-active,
.fadeup-leave-active {
  transition: all 1s ease;
  transition-delay: 0.2s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease;
}
</style>
