<template>
  <div class="mb-40 w-full flex flex-col items-center justify-center">
    <!-- phases number navigators -->
    <div
      class="customAnimate w-full flex items-start justify-center flex-wrap mb-8">
      <!-- img -->
      <!-- 'border-[4px] border-black' : index = activeIndex -->
      <div
        v-for="(phase, index) in phases"
        :key="index"
        class="p-1 rounded-full overflow-hidden transition-all duration-500 ease-in-out hover:border-clr-gray"
        :class="{
          'border-[4px] border-clr-accent': index == activeIndex,
          'border-[4px] border-transparent': index !== activeIndex,
        }">
        <div
          class="text-clr-white text-2xl cursor-pointer p-4"
          @click="changeSlide(index)">
          {{ index + 1 }}
        </div>
      </div>
    </div>

    <!-- slider -->
    <div class="w-full relative flex items-center justify-center">
      <div class="w-full md:w-[80%] lg:w-[65%] flex overflow-hidden">
        <!-- slides -->
        <div
          v-for="(phase, index) in phases"
          :key="index"
          class="w-full flex-shrink-0 transition-all duration-500 ease-in-out"
          :class="{
            'opacity-100 duration-1000': index == activeIndex,
            'opacity-0 duration-1000': index != activeIndex,
          }"
          :style="{ transform: 'translateX(' + -activeIndex * 100 + '%)' }">
          <div class="w-full text-center">
            <h5
              class="customAnimate text-clr-white font-medium text-fs-18 md:text-fs-20 mb-4">
              {{ phase.name }}
            </h5>
            <p
              class="customAnimate text-clr-gray-light text-fs-20 lg:text-[24px] font-extralight">
              {{ phase.text }}
            </p>
          </div>
        </div>
      </div>

      <!-- buttons -->
      <div
        class="customAnimate w-full absolute top-[0] lg:top-[50%] left-0 flex items-center justify-between">
        <button
          @click="prevSlide"
          :disabled="activeIndex === 0"
          class="disabled:opacity-30">
          <span
            class="w-10 h-10 bg-clr-gray-light rounded-full text-transparent flex items-center justify-center hover:scale-[1.15] transition-all ease-in-out duration-500 rotate-180">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#E6E6E6"
              class="w-5 h-5">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </span>
        </button>
        <button
          @click="nextSlide"
          :disabled="activeIndex === phases.length - 1"
          class="disabled:opacity-30">
          <span
            class="w-10 h-10 bg-clr-gray-light rounded-full text-transparent flex items-center justify-center hover:scale-[1.15] transition-all ease-in-out duration-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#E6E6E6"
              class="w-5 h-5">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
            </svg>
          </span>
        </button>
      </div>
    </div>
    <!-- <img v-for="img in images" :key="img.img" :src="img.img" alt=""> -->
  </div>
</template>

<script>
import FadeInMixin from "@/mixins/fadeInMixin.js";
export default {
  name: "PhaselSlider",
  mixins: [FadeInMixin],
  data() {
    return {
      activeIndex: 0,
      slideDirection: "",
      phases: [
        {
          name: "Phase 1: Product Discovery",
          text: " Our Site Ninjas' first mission is client and product research. To succeed, we learn from you. We start a discovery journey to understand your goals and audience. You may fill out our form or talk with our teams. ",
        },
        {
          name: "Phase 2: Strategic Planning",
          text: "Using findings from the Discovery phase, our Site Ninjas build strategy and make design suggestions. We offer recommendations on UX, information architecture, site maps, and functionalities. Detailed insights for the Strategic Architecture process will be provided to show user engagement. ",
        },
        {
          name: "Phase 3: UI/UX Design",
          text: " In the third phase, our Site Ninjas' creativity shines. Within days, we'll share a live web address for your website construction. The first task is designing a logo that reflects your business values, setting the color scheme. We'll create home page designs, exploring creativity with background elements, imagery, and animations. Once approved, we'll design remaining pages. Detailed insights for UI design will follow upon engagement. ",
        },
        {
          name: "Phase 4: Programming Code",
          text: " Our Nerdy Ninjas unleash a cold blooded assassination plot on the coding. We mark-up the approved creatives to HTML 5, CSS, Query, Angular/ React, among with a suitable programming language and test all code in the latest version of designated browsers. ",
        },
        {
          name: "Phase 5: Quality Control",
          text: "This step is critical as Site Ninjas review the site thoroughly before launch. We use modern industry tools for testing, checking functionality and content. We ensure all expectations are met, providing insights into bugs and their statuses for your review. ",
        },
        {
          name: "Phase 6: The Launch",
          text: " With a launch plan in place, the final phase of the project is to launch the website publicly. This includes reviewing all files and confirming one last time that all content is viewable, accurate and functional as it was on the development server.  ",
        },
        {
          name: "Phase 7: Client Training",
          text: " After a thorough QA process, we offer clients participation in our Client Backend Training Programme. This ensures understanding of design tools for future changes. We're available for assistance if needed. If you opt out of training, you can still choose site maintenance. It includes safety updates, malware scanning, and up to 10 visual website changes monthly. We offer three maintenance levels. If maintenance isn't for you, we're still here for changes at a flat hourly rate.",
        },
        {
          name: "Initial Interaction",
          text: " You hear about Site Ninjas Creative Digital Design Agency and want a quote for the work required in order to fulfil your digital dream. You can either call us or send us a email in which after brief understanding of your needs we will give an estimated first quote for the works to be completed. If you are happy with this we can move onto the next phase where we will learn of your business where we will be able to give you a more in depth and ballpark quote. ",
        },
      ],
    };
  },
  methods: {
    changeSlide(index) {
      this.slideDirection =
        index > this.activeIndex ? "slide-left" : "slide-right";

      this.activeIndex = index;
    },
    prevSlide() {
      this.slideDirection = "slide-right";

      this.activeIndex =
        (this.activeIndex - 1 + this.phases.length) % this.phases.length;
    },
    nextSlide() {
      this.slideDirection = "slide-left";
      this.activeIndex = (this.activeIndex + 1) % this.phases.length;
    },
  },
};
</script>
<style>
/* for qupte icon */
.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
</style>
