import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/portfolio",
    name: "portfolio",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "portfolio" */ "../views/PortfolioView.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ServicesView.vue"),
  },
  {
    path: "/services/web-design",
    name: "web-design",
    component: () =>
      import(/* webpackChunkName: "web-design" */ "../views/WebDesignView.vue"),
  },
  {
    path: "/services/web-development",
    name: "web-development",
    component: () =>
      import(
        /* webpackChunkName: "web-development" */ "../views/WebDevView.vue"
      ),
  },
  {
    path: "/services/wordpress",
    name: "wordpress",
    component: () =>
      import(/* webpackChunkName: "wordpress" */ "../views/WordPressView.vue"),
  },
  {
    path: "/services/brand-identity",
    name: "brand-identity",
    component: () =>
      import(
        /* webpackChunkName: "brand-identity" */ "../views/BrandIdentityView.vue"
      ),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/ContactView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0, behavior: "smooth" };
  },
});

export default router;
