<template>
  <!-- adding z index here to make menu's z index higher then whole router view's z index -->
  <div
    class="fixed top-0 left-0 right-0 z-[2] transition-all duration-700 delay-75 ease-in-out"
    :class="{ '-translate-y-full': !isSticky }">
    <MainNav />
  </div>
  <router-view />
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import MainNav from "@/components/MainNav.vue";

export default {
  name: "HomeView",
  components: {
    MainNav,
  },
  data() {
    return {
      isSticky: true,
      lastScroll: 0,
    };
  },
  methods: {
    handleScroll() {
      const currentScroll =
        window.scrollY || document.documentElement.scrollTop;

      if (currentScroll > this.lastScroll && currentScroll > 64) {
        this.isSticky = false; // scrolling down
      } else {
        this.isSticky = true; // scrolling up
      }

      this.lastScroll = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
    },
    handleResize() {
      // Handle resize logic here
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style>
.sticky-nav {
  position: fixed;
  top: 0;
  z-index: 2;
  /* Add any additional styling for sticky nav */
}
</style>
